





















































































































































































































































































































































































































































































#share_page_qrcode {
	img,
	canvas {
		display: block;
		width: 100%;
		height: 100%;
	}
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;